import { ref } from 'vue' 
import enumAll from '@/store/modules/enumAll.js'
export const iptData = ref([
  { 
    type: 'input',
    placeholder: '客户名称',
    value: null,
    prop: 'customerName',
    width: '10%'
  },
  { 
    type: 'input',
    placeholder: '对账人',
    value: null,
    prop: 'sponsor',
    width: '10%'
  },
  {
    type: 'select',
    placeholder: '状态',
    value: null,
    prop: 'status',  
    width: '10%',
    opt:[
      {
      label: '新增对账',
      value: 0
     },
     {
      label: '已申请',
      value: 1
     },
     {
      label: '已完成',
      value: 2
     },
  ]
  },
  {
    type: 'input',     
    placeholder: '账单号',
    value: null,
    prop: 'id',
    width: '13%'
  },
  {
    type: 'input',
    placeholder: '订单号',
    value: null,
    prop: 'orderId',  
    width: '13%'
  },
  {
    type: 'input',
    placeholder: '流程编号',
    value: null,
    prop: 'flowInstanceId',  
    width: '13%'
  },


])
export const columns =ref([
  {
    title: '账单号',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: '对账时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '客户',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '流程编号',
    dataIndex: 'flowInstanceId',
    align: 'center',
  },
  {
    title: '对账金额',
    dataIndex: 'amt',
    align: 'center',
  },
  {
    title: '对账人',
    dataIndex: 'sponsor',
    align: 'center',
  },
  {
    title: '状态',
    dataIndex: 'status.label',
    align: 'center',
  },
  {
    title: '对账回执（照片）',
    dataIndex: 'fileUrl',
    slots: { customRender: 'fileUrl' },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: { customRender: 'add' },
    align: 'center',
  },
])

export const orderNewAdd = ref([
  {  
    type: 'selectTime',
    placeholder: '下单时间起',
    value: null,
    prop: 'startTime',
    width: '200px',
  },
  {
    type: 'selectTime',
    placeholder: '下单时间止',
    value: null,
    prop: 'endTime',
    width: '200px',
  },
  {
    type: 'input',     
    placeholder: '挂账类型',
    value: null,
    prop: 'settlementMode',
    width: '13%'
  },
  {
    type: 'input',
    placeholder: '合同号',
    value: null,
    prop: 'orderId',  
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '业务员',
    value: null,
    prop: 'salesman',  
    width: '10%'
  },
  { 
    type: 'input',
    placeholder: '车牌号',
    value: null,
    prop: 'vinNo',
    width: '10%'
  },
  { 
    type: 'input',
    placeholder: '业务对接人',
    value: null,
    prop: 'businessContactPerson',
    width: '10%'
  }
])
export const tansIptData = ref([
  {  
    type: 'input',
    placeholder: '起运地',
    value: null,
    prop: 'startAddress',
    width: '200px',
  },
  {  
    type: 'input',
    placeholder: '目的地',
    value: null,
    prop: 'endAddress',
    width: '200px',
  },
  {  
    type: 'select',
    placeholder: '板车类型',
    value: null,
    prop: 'truckType',
    width: '170px',
    opt:enumAll.state.truckType
  },
  {  
    type: 'input',
    placeholder: '派车人',
    value: null,
    prop: 'operator',
    width: '170px',
  },
  {  
    type: 'input',
    placeholder: '小车车牌',
    value: null,
    prop: 'vinNo',
    width: '170px',
  },
  {  
    type: 'input',
    placeholder: '大车车牌',
    value: null,
    prop: 'carNo',
    width: '170px',
  },
  {  
    type: 'input',
    placeholder: '司机姓名',
    value: null,
    prop: 'driverName',
    width: '170px',
  },
])
export const orderColumns =ref([
  {
    title: '操作',
    dataIndex: 'orderIdDel',
    slots: { customRender: 'orderIdDel' },
    align: 'center',
  },
  {
    title: '合同编号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '结算类型',
    dataIndex: 'settlementMode.label',
    align: 'center',
  },
  {
    title: '挂账类型',
    dataIndex: 'creditType.label',
    align: 'center',
  },
  {
    title: '客户名',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '业务对接人',
    dataIndex: 'businessContactPerson',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '起运地-目的地',
    dataIndex: 'startAddress',
    slots:{ customRender: 'startAddress' },
    align: 'center',
  },
  {
    title: '下单时间',
    dataIndex: 'orderTime',
    align: 'center',
  },
  {
    title: '起运时间',
    dataIndex: 'startTime',
    align: 'center',
  },
  {
    title: '结算金额',
    dataIndex: 'settableAmt',
    slots: { customRender: 'settableAmt' },
    align: 'center',
  },
  {
    title: '小车信息',
    dataIndex: 'vehicleList',
    slots: { customRender: 'vehicleList' },
    align: 'center',
  },
  {
    title: '到收金额',
    dataIndex: 'paymentArrival',
    align: 'center',
  },
  {
    title: '结算方式',
    dataIndex: 'orderSettlement.label',
    align: 'center',
  },

  {
    title: '结算状态',
    dataIndex: 'settlementStatus.label',
    align: 'center',
  },
])

export const waybillColumns =ref([
  {
    title: '操作',
    dataIndex: 'orderIdDel',
    slots: { customRender: 'orderIdDel' },
    align: 'center',
  },
  {
    title: '结算类型',
    dataIndex: 'settlementMode.label',
    align: 'center',
  },
  {
    title: '车牌号/司机',
    dataIndex: 'carNo',
    align: 'center',
  },
  {
    title: '司机姓名',
    dataIndex: 'driverName',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'carrierName',
    align: 'center',
  },
  {
    title: '线路',
    dataIndex: 'startAddress',
    slots:{ customRender: 'startAddress' },
    align: 'center',
  },
  {
    title: '发车时间',
    dataIndex: 'startTime',
    align: 'center',
  },
  {
    title: '类型',
    dataIndex: 'truckType.label',
    align: 'center',
  },
  {
    title: '派车人',
    dataIndex: 'loader',
    align: 'center',
  },
  {
    title: '派车部门',
    dataIndex: 'orgName',
    align: 'center',
  },
  {
    title: '到达时间',
    dataIndex: 'endTime',
    align: 'center',
  },
  {
    title: '总结算金额',
    dataIndex: 'settableAmt',
    align: 'center',
  },
  {
    title: '小车信息',
    dataIndex: 'vehicleList',
    slots: { customRender: 'vehicleList' },
    align: 'center',
  },
  {
    title: '油卡结算金额',
    dataIndex: 'settableOilAmt',
    align: 'center',
  },
  {
    title: '转账结算金额',
    dataIndex: 'settableCardAmt',
    align: 'center',
  },
  {
    title: '拆分',
    dataIndex: 'add',
    slots: { customRender: 'add' },
    align: 'center',
  },
])

export const orderReconColumns =ref([
  {
    title: '操作',
    dataIndex: 'orderIdDel',
    slots: { customRender: 'orderIdDel' },
    align: 'center',
  },
  {
    title: '合同编号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '结算类型',
    dataIndex: 'settlementMode.label',
    align: 'center',
  },
  {
    title: '挂账类型',
    dataIndex: 'creditType.label',
    align: 'center',
  },
  {
    title: '客户名',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '业务对接人',
    dataIndex: 'businessContactPerson',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '起运地-目的地',
    dataIndex: 'startAddress',
    slots:{ customRender: 'startAddress' },
    align: 'center',
  },
  {
    title: '下单时间',
    dataIndex: 'orderTime',
    align: 'center',
  },
  {
    title: '起运时间',
    dataIndex: 'startTime',
    align: 'center',
  },
  {
    title: '合同金额',
    dataIndex: 'contractAmt',
    align: 'center',
  },
  {
    title: '申请结算',
    dataIndex: 'nowSettledAmt',
    align: 'center',
  },
  {
    title: '已结算',
    dataIndex: 'settledAmt',
    align: 'center',
  },
  {
    title: '小车信息',
    dataIndex: 'vehicleList',
    slots: { customRender: 'vehicleList' },
    align: 'center',
  },
  {
    title: '到收金额',
    dataIndex: 'paymentArrival',
    align: 'center',
  },
  {
    title: '结算方式',
    dataIndex: 'orderSettlement.label',
    align: 'center',
  },

  {
    title: '结算状态',
    dataIndex: 'settlementStatus.label',
    align: 'center',
  },
])
