<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #tail>
        <a-button type="primary" @click="placeAddOrder">+新增对账</a-button>
        <a-button class="m-l1" :loading="btnLoading" @click="mergeAdd">合并账单</a-button>
      </template>
    </ly-searchList>
    <div class="m-t1 tableBox">
    <ly-table :columns="columns" :rowSelectionShow="true" @onChange="onChange" rowKey="id" ref="tableRef" size="small" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
      <template #fileUrl="{ record }">
        <a-image v-if="record.fileId !== null" :width="80" :src="record.fileUrl" :preview="record.preview" @click="handlePreview(record)" />
      </template>
      <template #add="{ record }">
        <a @click="generateDetails(record.id)">查看</a>
        <a-popconfirm title="确定要删除吗?" ok-text="确定" cancel-text="取消" @confirm="confirm(record)" >
          <span v-if="record.status?.value === 0" class="m-l2 color-R cur-p">删除</span>
        </a-popconfirm>
      </template>
    </ly-table>
  </div>
    <!-- 新增账单 -->
    <NewBillModal v-if="billVisible" @submitSuccess="submitSuccess" v-model:visible="billVisible"></NewBillModal>
    <!-- 查看编辑 -->
    <EditModal v-if="editVisible" v-model:visible="editVisible" @stastus="submitSuccess" :editID="editID"></EditModal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { iptData, columns } from './income'
import { reconciliationPage, statementVehicleDelete, reconciliationMerge } from '@/api/finance/income'
import NewBillModal from './components/newBillModal'
import EditModal from './components/editModal'
import { message } from 'ant-design-vue'

const tableRef = ref(null)
const loading = ref(false)
const billVisible = ref(false)
const editVisible = ref(false)
const editID = ref('')
const data = ref([])
const searchMsg = ref({})
const selectIds = ref([])
const btnLoading = ref(false)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
//分页事件
const pageChange = (e) => {
  pagination.value = e
  reqReconciliationPage()
}
//合并账单
const onChange =  (e,v) => {
  selectIds.value = e
}
const mergeAdd = () =>{
   if(selectIds.value.length === 0){
    message.warning('请选择要合并的账单')
    return
  }
  if(selectIds.value.length === 1){
    message.warning('至少选择两个账单进行合并')
    return
  }
  btnLoading.value = true
  reconciliationMerge({
    ids:selectIds.value
  }).then( res => {
    if (res.code !== 10000) return
   message.success(res.msg)
   reqReconciliationPage()
  }).finally(() => {
    btnLoading.value = false
  })
}
// 删除
const confirm = (record) => {  
  statementVehicleDelete(record.id).then( res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    if(data.value.length === 1 && pagination.value.current > 1){
      pagination.value.current --
    }
    reqReconciliationPage()
  })
}
const searchAdd = (e) => {
  pagination.value.current = 1
  searchMsg.value = e
  reqReconciliationPage()
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqReconciliationPage()
}
// 查看
const generateDetails = (id) => {
  editID.value = id
  editVisible.value = true
}
//列表分页
const reqReconciliationPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  reconciliationPage(msg).then(res => {
    if (res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total
    console.log(res);
  }).finally(() => {
    loading.value = false
  })
}
//新增账单
const placeAddOrder = () => {
  billVisible.value = true
}
const submitSuccess = () => {
  reqReconciliationPage()
}
const handlePreview = (item) => {
  item.preview = true
  item.fileUrl = item.fileRealUrl
}
onMounted(() => {
  reqReconciliationPage()
})
</script>

<style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  min-height: calc(100vh - 230px);
}
</style>