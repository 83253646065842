<template>
  <div>
    <a-modal :visible="visible" title="对账" width="95%" @cancel="cancel" :centered="true" :footer="null">
      <div class="flex ju-center">
        <div style="width: 40%" class="flex al-center">
          <a-select v-model:value="customerId" placeholder="请输入" style="width: 100%" :filter-option="false" @change="celCustomer" :show-arrow="false" size="large" @search="carrierAutoComplete" show-search>
            <template v-if="loading" #notFoundContent>
              <div class=" flex ju-center"><a-spin size="small" /></div>
            </template>
            <a-select-option v-if="!loading" v-for="item in carriers" :key="item.id" :title="item.name">
              <span>{{ item.name }}</span>
              <!-- -{{ item.mobile }} -->
              <span style="float: right">{{ item.taxpayerIdentificationNum }}</span>
            </a-select-option>
          </a-select>
          <a-button class="m-l1" style="height: 40px; width: 100px" @click="searchAdd" type="primary">搜索</a-button>
          <a-button class="m-l1" style="height: 40px; width: 150px"  @click="submit" danger>保存账单</a-button>
        </div>
      </div>
      <div>
        <div class="title">订单明细</div>
        <div class="contentBox">
          <div class="tips">
            <span>已选择<span style="margin: 0 5px;">{{ celOrder.length }}</span> 条</span>
            <span class="m-l2">总金额：<span style="color:red">￥{{ orderAmtNum }}</span></span>
            <a class="m-l4" @click="empty(0)">清空选择</a>
          </div>
          <div>
            <ly-searchList :iptData="orderNewAdd" @searchAdd="searchOrder" @resetBtn="resetBtnOrd">
            </ly-searchList>
          </div>
          <div class="orderBox">
            <ly-table :columns="orderColumnsNew" @onChange="onChange" ref="tableRefOrd" size="small" rowKey="id" :rowSelectionShow="true" :data="orderData" :loading="loadingOrd">
              <template #settableAmt="{record}">
                <div>
                  <span v-show="record.editStatus"> {{record.settableAmt}}</span>
                  <a-input v-show="!record.editStatus" v-model:value="record.settableAmt" style="width:85px;" /><br />
                  <a @click="editBtn(record)" v-if="selectedOrderRowKeys.indexOf(record.id) > -1">{{record.editStatus ? '编辑' : '确认'}}</a>
                </div>
              </template>
              <template #vehicleList="{ record }">
                <div v-for="(item,index) in record.vehicleList" :key="index">
                  <a-tag color="#365DD2" style="cursor: pointer;">车架号：{{item.vinNo}} 金额：{{item.transportFee}}元</a-tag><br />
                </div>
              </template>
              <template #startAddress="{ record }">
                {{ record.startAddress }}
                <div>{{ record.endAddress }}</div>
              </template>

            </ly-table>
          </div>
        </div>
      </div>
      <div>
        <div class="title m-t1">运单明细</div>
        <div class="contentBox">
          <div class="tips">
            <span>已选择<span style="margin: 0 5px;">{{ celTrans.length }}</span> 条</span>
            <span class="m-l2">总金额：<span style="color:red">￥{{ transAmtNum }}</span></span>
            <a class="m-l4" @click="empty(1)">清空选择</a>
          </div>
          <div>
            <ly-searchList :iptData="tansIptData" @searchAdd="searchTran" @resetBtn="resetBtnTran">
            </ly-searchList>
          </div>
          <div class="orderBox">
            <ly-table :columns="waybillColumnsNew" rowKey="id" ref="tableRefTran" @onChange="onChangeTran" :rowSelectionShow="true" size="small" :data="waybillData" :loading="loadingTran">
              <template #vehicleList="{ record }">
                <div v-for="(item,index) in record.vehicleList" :key="index">
                  <a-tag color="#365DD2" style="cursor: pointer;">车架号：{{item.vinNo}} 金额：{{item.transportFee}}元</a-tag><br />
                </div>
              </template>
              <template #startAddress="{ record }">
                {{ record.startAddress }}
                <div>{{ record.endAddress }}</div>
              </template>
              <template #add="{record}">
                <a-popconfirm title="确定要拆分吗?" ok-text="确定" cancel-text="取消" @confirm="splitAdd(record)">
                  <a>拆分</a>
                </a-popconfirm>
                <a class="m-l1" @click="amountAdd(record)">修改金额</a>
              </template>
            </ly-table>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 修改金额 -->
    <ly-modal v-model:visible="startVisible" title="修改金额" @handleOk="driverOk">
      <div class="money  m-b1">
        总金额<span class="m-l1">{{ totalAmount }}<span class="color-R">（注：油卡与转账金额总和必须等于该数值）</span></span>
      </div>
      <a-input addon-before="油卡" v-model:value="driver.oilCard" :min="0" type='number' size="large">
        <template #suffix>
          <a-tooltip title="全部油卡">
            <EnterOutlined @click="driver.oilCard = totalAmount, driver.bankCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input>
      <div class="m-t2 m-b2">
        <a-input addon-before="转账" v-model:value="driver.bankCard" :min="0" type='number' size="large">
          <template #suffix>
            <a-tooltip title="全部转账">
              <EnterOutlined @click="driver.bankCard = totalAmount, driver.oilCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </template>
        </a-input>
      </div>
    </ly-modal>

    <ly-modal v-model:visible="secondaryVisible" title="对账回执" @handleOk="secondaryOk" :loading="loadingOk">
      <ly-upload ref="uploadRef" v-if="secondaryVisible" :maxMun="1" @fileStatus="fileStatus"></ly-upload>
    </ly-modal>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { orderNewAdd, tansIptData, orderColumns, waybillColumns } from '../income'
import { pageList } from '@/api/crmManagement/crm'
import { debounce, compute } from '@/utils/util'
import { reconciliationBillOrder, reconciliationBillTrans, reconciliationAdd, splitTrans, changeOilFee } from '@/api/finance/income'
import { message } from "ant-design-vue";
import { cloneDeep } from "lodash-es";
import { transitionTimeYMD } from '@/utils/util'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});
const orderColumnsNew = orderColumns.value.slice(1, orderColumns.value.length)
const waybillColumnsNew = waybillColumns.value.slice(1, waybillColumns.value.length)
const emit = defineEmits(['update:visible', 'submitSuccess'])
const secondaryVisible = ref(false)
const loading = ref(false)
const customerId = ref("");
const customerName = ref("");
const carriers = ref([]);
const state = reactive({ name: '' })
const orderData = ref([])
const waybillData = ref([])
const tableRefOrd = ref(null)
const tableRefTran = ref(null)
const celOrder = ref([])
const selectedOrderRowKeys = ref([])
const celTrans = ref([])
const orderAmtNum = ref(0)
const transAmtNum = ref(0)
const loadingOrd = ref(false)
const loadingTran = ref(false)
const totalAmount = ref(0)
const startVisible = ref(false)
const searchAMsgOrd = ref({})
const searchAMsgTran = ref({})
const uploadRef = ref(null)
const loadingOk = ref(false)
const driver = reactive({
  oilCard: 0,
  bankCard: 0,
  transportId: '',
})

const celCustomer = (e, v) => {
  customerName.value = v.title
}

const searchOrder = (e) => {
  if (!customerId.value) {
    message.error('请先选择客户')
    return
  }
  searchAMsgOrd.value = cloneDeep(e)
  searchAMsgOrd.value.startTime = transitionTimeYMD(e.startTime)
  searchAMsgOrd.value.endTime = transitionTimeYMD(e.endTime)
  empty(0)
  reqReconciliationBillOrder()
}
const resetBtnOrd = () => {
  searchAMsgOrd.value = {}
  empty(0)
  reqReconciliationBillOrder()
}
const searchTran = (e) => {
  if (!customerId.value) {
    message.error('请先选择客户')
    return
  }
  searchAMsgTran.value = cloneDeep(e)
  empty(1)
  reqReconciliationBillTrans()
}
const resetBtnTran = () => {
  searchAMsgTran.value = {}
  empty(1)
  reqReconciliationBillTrans()
}
const onChange = (e, v) => {
  selectedOrderRowKeys.value = e
  celOrder.value = v
  let orderAmt = []
  celOrder.value.forEach(item => {
    if (Number(item.settableAmt) > Number(item.applyAmt)) {
      item.settableAmt = item.applyAmt
    }
    orderAmt.push(item.settableAmt)
  })
  orderAmtNum.value = celOrder.value.length > 0 ? compute('+', ...orderAmt).result : 0
}
const onChangeTran = (e, v) => {
  celTrans.value = v
  let transAmt = []
  celTrans.value.forEach(item => {
    transAmt.push(item.settableAmt)
  })
  transAmtNum.value = celTrans.value.length > 0 ? compute('+', ...transAmt).result : 0
}
// 修改金额
const driverOk = () => {
  if (compute('+', driver.oilCard, driver.bankCard).result !== Number(totalAmount.value)) {
    message.error('油卡与转账金额总和必须等于总金额，请检查')
    return
  }
  let smg = {
    cardAmt: driver.bankCard,
    oilAmt: driver.oilCard,
    id: driver.transportId
  }
  changeOilFee(smg).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    startVisible.value = false
    reqReconciliationBillTrans()
  })
}
const amountAdd = (record) => {
  totalAmount.value = record.settableAmt
  driver.oilCard = record.settableOilAmt
  driver.bankCard = record.settableCardAmt
  driver.transportId = record.id
  startVisible.value = true
}
// 拆分
const splitAdd = (record) => {
  splitTrans(record.id).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    reqReconciliationBillTrans()
  })
}
//清空选择
const empty = (type) => {
  if (type == 0) {
    tableRefOrd.value.selectedRowKeys = []
    selectedOrderRowKeys.value = []
    celOrder.value = []
    orderAmtNum.value = 0
  } else {
    tableRefTran.value.selectedRowKeys = []
    celTrans.value = []
    transAmtNum.value = 0
  }

}
//保存开票
const submit = () => {
  if (celOrder.value.length === 0 && celTrans.value.length === 0) {
    message.error('请选择订单明细或运单明细')
    return
  }
  secondaryVisible.value = true
}
const fileStatus = (e) => {
  loadingOk.value = e
}
const secondaryOk = () => {
  let fileIds = uploadRef.value.state.fileIds
  let orderSettlementIds = []
  celOrder.value.forEach(item => {
    orderSettlementIds.push({
      applyAmt: item.settableAmt,
      id: item.id
    })
  })
  let transSettlementIds = []
  celTrans.value.forEach(item => {
    transSettlementIds.push({
      applyAmt: item.settableAmt,
      id: item.id
    })
  })
  let addAmt = compute('-', orderAmtNum.value, transAmtNum.value).result
  let msg = {
    amt: addAmt,
    orderSettlementIds: orderSettlementIds,
    transSettlementIds: transSettlementIds,
    customerId: customerId.value,
    customerName: customerName.value,
    fileId: fileIds.length > 0 ? fileIds[0].id : ''
  }
  loadingOk.value = true
  reconciliationAdd(msg).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    secondaryVisible.value = false
    cancel()
    emit('submitSuccess')
  }).finally(() => {
    loadingOk.value = false
  })
}
//根据客户id获取表格信息
const searchAdd = () => {
  reqReconciliationBillOrder()
  reqReconciliationBillTrans()
  empty(0)
  empty(1)
}
const carrierAutoComplete = val => {
  loading.value = true
  state.name = val
  debounce(state, reqPageList, 1000)
}
const reqPageList = () => {
  pageList({ name: state.name }).then(res => {
    if (res.code === 10000) {
      carriers.value = res.data
    } else {
      carriers.value = []
    }
  }).finally(() => {
    loading.value = false
  })
}

// 订单信息
const reqReconciliationBillOrder = () => {
  loadingOrd.value = true
  reconciliationBillOrder(customerId.value, searchAMsgOrd.value).then(res => {
    if (res.code !== 10000) return
    orderData.value = res.data
    orderData.value.forEach(o => {
      o.editStatus = true
      o.applyAmt = o.settableAmt
    })
    console.log(res);
  }).finally(() => {
    loadingOrd.value = false
  })
}
// 运单信息
const reqReconciliationBillTrans = () => {
  loadingTran.value = true
  reconciliationBillTrans(customerId.value, searchAMsgTran.value).then(res => {
    if (res.code !== 10000) return
    waybillData.value = res.data
  }).finally(() => {
    loadingTran.value = false
  })
}
const cancel = () => {
  emit('update:visible', false)
}
// 新增账单结算金额编辑
const editBtn = (record) => {
  if (Number(record.applyAmt) < 0) {
    message.error('不能修改负数')
    return
  }
  if (Number(record.settableAmt) > 0) {
    if (Number(record.settableAmt) > Number(record.applyAmt)) {
      message.error('数额高于最' + Number(record.applyAmt) + '大值')
      return
    }
    record.editStatus = !record.editStatus
    if (record.editStatus) {
      orderAmtNum.value = compute('+', ...celOrder.value.map(item => item.settableAmt)).result
    }
  } else { message.error('数额不能小于0') }
}
</script>

<style lang="less" scoped>
:deep(.navBox) {
  padding: 0;
}

.title {
  font-size: 16px;
  font-weight: 700;
}
.tips {
  margin: 5px 0;
  font-size: 12px;
}

.orderBox {
  max-height: 400px;
  overflow: auto;
}
</style>
